<template>
  <admin-page-card
    pageName="Group Profile Settings"
    iconClass="fa fa-sliders fa-lg"
  >
    <div class="single-click-default">
      <v-wait for="fetchingDetails">
        <template slot="waiting">
          <content-placeholders :rounded="true">
            <content-placeholders-text :lines="20"></content-placeholders-text>
          </content-placeholders>
        </template>

        <div class="row">
          <div class="col-sm-6 ">
            <h5>
              <b-form-group
                label="Group"
                label-for="group"
                :label-cols="3"
                :horizontal="true"
                class=""
              >
                <multiselect
                  v-model="selectedGroup"
                  :options="groups"
                  :multiple="false"
                  :close-on-select="true"
                  :showLabels="false"
                  track-by="id"
                  label="name"
                  placeholder="Select Group"
                  @close="getUsersWithCustomSettings"
                >
                </multiselect>
              </b-form-group>
            </h5>
          </div>
        </div>
        <br />
        <div class="row" v-if="selectedGroup">
          <div class="col-sm-6">
            <h5>Users With Custom Settings</h5>
          </div>
        </div>
        <div class="row" v-if="selectedGroup">
          <div class="col-sm-12">
            <b-table striped small show-empty :items="users" :fields="fields">
              <template v-slot:cell(first_name)="data">
                {{ data.item.first_name + ' ' + data.item.last_name }}
              </template>
              <template v-slot:cell(role_id)="data">
                {{ roles[data.item.role_id] }}
              </template>
            </b-table>
          </div>
        </div>
        <div class="row pt-4">
          <div class="col-sm-12 text-center">
            <b-button
              size
              variant="secondary"
              class="fw-btn mx-3"
              @click="closeModal"
              >Cancel</b-button
            >
            <b-button
              size=""
              variant="primary"
              class=" mx-3"
              v-b-modal.updateConfirmation
              :disabled="disableOptions"
              >Use My Settings For Group
            </b-button>
            <b-button
              size=""
              variant="danger"
              class=" mx-3"
              v-b-modal.deleteConfirmation
              :disabled="disableOptions"
              >Clear All User Settings
            </b-button>
          </div>
        </div>
      </v-wait>
    </div>
    <b-modal
      id="deleteConfirmation"
      ref="deleteConfirmation"
      title="Clear Group User Settings"
      ok-title="Yes, Clear Users & Update Group Settings"
      @ok="clearUserSettingsAndRepopulate"
      @cancel="closeModal"
    >
      <p>
        Are you sure, you want to delete user settings and set your settings for
        the Group?
      </p>
    </b-modal>
    <b-modal
      id="updateConfirmation"
      ref="updateConfirmation"
      title="Use My Settings For Group"
      ok-title="Yes, Update Group Settings"
      @ok="saveGroupSettings"
      @cancel="closeModal"
    >
      <p>
        This will save your FIN Profile settings for the Group.
      </p>

      <p>Users with custom User-level settings will not be impacted.</p>

      <p>Do you want to continue?</p>
    </b-modal>
  </admin-page-card>
</template>
<script>
// api
import groupProfileSettingsAPI from '@/api/finapps/admin/group_profile_settings'
import Multiselect from 'vue-multiselect'
// api
// ui components
export default {
  name: 'GroupProfileSettings',
  components: {
    Multiselect
  },
  created() {
    this.loadAdminGroups()
  },
  data() {
    return {
      disableOptions: true,
      tabIndex: 0,
      groups: [],
      selectedGroup: null,
      users: [],
      fields: [
        { key: 'first_name', label: 'Name' },
        { key: 'email', label: 'Email' },
        { key: 'role_id', label: 'Role' }
      ],
      roles: {
        1: 'FIN Admin',
        2: 'User',
        3: 'Client Admin',
        4: 'Viewer',
        5: 'Partner Admin'
      }
    }
  },
  computed: {
    usersAvailable() {
      return this.users.length > 0
    }
  },
  methods: {
    loadAdminGroups() {
      this.$wait.start('fetchingDetails')
      return groupProfileSettingsAPI.groups().then(res => {
        this.groups = res.groups
        this.$wait.end('fetchingDetails')
      })
    },

    getUsersWithCustomSettings() {
      return groupProfileSettingsAPI
        .usersWithCustomSettings(this.selectedGroup.id)
        .then(res => {
          this.users = res
          this.disableOptions = false
        })
    },
    clearUserSettingsAndRepopulate() {
      let deleteUserConfig = true
      return groupProfileSettingsAPI
        .repopulateGroupConfig(this.selectedGroup.id, deleteUserConfig)
        .then(res => {
          this.users = res
          this.$toasted.global.action_success(
            'User Settings cleared and New Group Settings successfully updated.'
          )
        })
    },
    saveGroupSettings() {
      let deleteUserConfig = false
      return groupProfileSettingsAPI
        .repopulateGroupConfig(this.selectedGroup.id, deleteUserConfig)
        .then(() => {
          this.$toasted.global.action_success(
            'Group Settings successfully updated.'
          )
        })
    },
    closeModal() {
      this.selectedGroup = null
      this.disableOptions = true
    }
  }
}
</script>
