import axios from 'axios'
export default {
  // groupSections: groupId => {
  //   return axios
  //     .get(
  //       '/api/group_sections?group_id=' + groupId,

  //       {
  //         handleErrors: true
  //       }
  //     )
  //     .then(res => {
  //       return res.data
  //     })
  // },
  // updateGroupSections: (groupId, sections) => {
  //   return axios
  //     .put(
  //       '/api/group_sections/set',
  //       { group_id: groupId, group_sections: sections },
  //       { handleErrors: true }
  //     )
  //     .then(res => {
  //       return res.data
  //     })
  // },
  // sectionConfigs: (groupId, institutionId) => {
  //   return axios
  //     .get(
  //       '/api/group_section_configurations?group_id=' +
  //         groupId +
  //         '&financial_institution_id=' +
  //         institutionId,

  //       {
  //         handleErrors: true
  //       }
  //     )
  //     .then(res => {
  //       return res.data.section_configs
  //     })
  // },
  groups: () => {
    return axios
      .get('/api/admin/groups/client_groups', {
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  },
  repopulateGroupConfig: (groupId, deleteUserConfig) => {
    return axios
      .put(
        '/api/admin/group_sections/repopulate_client_group_configuration',
        {
          group_id: groupId,
          delete_user_config: deleteUserConfig
        },
        {
          handleErrors: true
        }
      )
      .then(res => {
        return res.data.users
      })
  },
  usersWithCustomSettings: groupId => {
    return axios
      .get(
        '/api/admin/group_sections/client_group_users_with_custom_data?group_id=' +
          groupId,

        {
          handleErrors: true
        }
      )
      .then(res => {
        return res.data.users
      })
  }
}
